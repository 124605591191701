import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import NavAdmin from "../components/navAdmin/NavAdmin";
import "./OrdersPage.css"
import {getOrders} from "../http/orderAPI";
const OrderPage = observer(() => {

    const [orders, setOrders] = useState([])
    useEffect(()=> {
        getOrders().then(data=>{
            if(data.length>0){
                setOrders(data.reverse())
            }
        }).finally()
    }, [])
    return (

        <div style={{background: "#141414"}}>
            <div>
                <NavAdmin/>
            </div>

            <div style={{margin: "100px 100px 100px 431px", minHeight: "calc(100dvh - 200px)"}}>
                <h2 style={{width: "100%", padding: "40px 50px", background: "#1C1C1C", borderTopRightRadius: "30px", borderTopLeftRadius: "30px", color: "#fff", fontSize: "22px", fontWeight: "500"}}>Заявки</h2>
                <table className="darkTable" cellSpacing="0" cellPadding="0">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Дата</th>
                        <th>Товары</th>
                        <th>Сумма</th>
                        <th>Заказчик</th>
                        <th>Телефон</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orders.length !== 0 && orders.map(item =>
                            <tr key={item.id}><td>{item.id}</td><td>{item.date}</td><td>{item.title}</td><td>{item.cost}₽</td><td>{item.name}</td><td>{item.telephone}</td></tr>
                        )
                    }

                    </tbody>

                </table>
                {
                    orders.length === 0  && <div style={{color: "#fff", padding: "50px 0", width: "100%", textAlign:"center"}}>Нет заявок</div>
                }
            </div>


        </div>
    );
});

export default OrderPage;