import React, {useContext, useEffect, useState} from 'react';
import styles from './loginPage.module.css'
import {login} from "../http/userAPI";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import Notify from "../components/notify/Notify";

const LoginPage = observer( () => {
    const [notify, setNotify] = useState({text: "", status: "", notify: false})
    const {user} = useContext(Context);
    const navigate = useNavigate();
    const [form, setForm] = useState({login: "", password: ""})
    useEffect(()=>{
        if(user._isAuth){
            navigate("/orders");
        }
    })
    const clickBtn = async () =>{
        try {
            let data = await login(form.login, form.password);
            localStorage.setItem('token', data.token);
            user.setUser(data);
            user.setIsAuth(true);
            navigate("/orders");
        } catch (e) {
            if(e.response.status === 403){
                if(notify.notify === false){
                    setNotify({...notify, text: "Неверные данные", status: "error", notify: true})
                    setTimeout(()=>setNotify({...notify, notify: false}), 2000)
                }


            }
        }

    }

    return (
        <div className={styles.block}>
            <Notify text={notify.text} notify={notify.notify} status={notify.status}/>
            <div className={styles.container}>
                <form className={styles.body}>
                    <div className={styles.title}>Вход</div>
                    <input className={styles.input} placeholder="Логин" value={form.login} onChange={e=> setForm({...form, login: e.target.value})}/>
                    <input className={styles.input} placeholder="Пароль" type="password" value={form.password} onChange={e => setForm({...form, password: e.target.value})}/>
                    <button className={styles.btn} onClick={clickBtn} >Войти</button>
                </form>
            </div>
        </div>
    );
});

export default LoginPage;