import React from 'react';
export const useScrollLock = () => {
    const lockScroll = React.useCallback(() => {
        document.body.style.overflow = 'hidden';
        if(document.querySelector(".Sort_body")){
            document.querySelector(".Sort_body").style.pointerEvents = "none";
        }
    }, [])

    const unlockScroll = React.useCallback(() => {
        document.body.style.overflowY = 'scroll';
        if(document.querySelector(".Sort_body")){
            document.querySelector(".Sort_body").style.pointerEvents = "all";
            document.querySelector(".Sort_body>*>*").style.background = "#fff"
        }
    }, [])

  return {
 lockScroll,
 unlockScroll
 };
}