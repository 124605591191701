import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
    <ContentLoader
        speed={2}
        width={274}
        height={478}
        viewBox="0 0 274 478"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="1" rx="30" ry="30" width="274" height="368" />
        <rect x="8" y="425" rx="0" ry="0" width="41" height="15" />
        <rect x="80" y="391" rx="0" ry="0" width="1" height="9" />
        <rect x="6" y="380" rx="0" ry="0" width="125" height="14" />
        <rect x="7" y="399" rx="0" ry="0" width="87" height="14" />
    </ContentLoader>
)

export default MyLoader