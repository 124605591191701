import React from 'react';
import Main from "../components/blocks/main/Main";
import Gallery from "../components/blocks/gallery/Gallery";
import AboutUs from "../components/blocks/aboutUs/AboutUs";
import Header from "../components/header/Header";
import Footer from "../components/blocks/footer/Footer";
import ProductList from "../components/productList/productList";
import {observer} from "mobx-react-lite"
import Feedback from "../components/blocks/feedback/Feedback";

const MainPage = observer( () => {
    return (
        <div>
            <Header/>
            <Main/>
            <ProductList/>
            <Gallery/>
            <AboutUs/>
            <Feedback/>
            <Footer/>
        </div>
    );


});

export default MainPage;