import React, {useContext, useMemo, useState} from 'react'
import ProductArticle from "../productArticle/productArticle";
import {observer} from "mobx-react-lite";
import Sort from "../UI/Sort";
import MyLoader from "../MyLoad/MyLoad";
import stylesArticle from "../productArticle/productArticle.module.css";
import styles from "./productList.module.css"
import {Context} from "../../index";
import Send from "../UI/popups/send";
const productList = observer( ({scroll}) => {
    const {product} = useContext(Context);
    let min = 0
    let max = 0;
    const [value, setValue] = useState({min: min, max: max})
    const startValue = useMemo(()=>{
        for (let j = 1; j < product.Products.length; j += 1 ){
            for (let i = 0; i < product.Products.length-1; i += 1 ){
                if(parseInt(product.Products[i].release)>parseInt(product.Products[i+1].release)){
                    let x = product.Products[i];
                    product.Products[i] = product.Products[i+1]
                    product.Products[i+1] = x
                }
            }
        }
        setValue({min: product.Products[0]?.release, max: product.Products[product.Products.length-1]?.release})
        return product.Products;
    }, [product.Products])

    const [page, setPage] = useState(8)

    if(startValue[0]){
        min = startValue[0].release
        max = startValue[startValue.length-1].release
    }


    const sorted = useMemo(()=>{
        let sortProduct = [];
        if(value.min>value.max){
            product.Products.forEach((item, index)=>{
                if(parseInt(item.release) >= parseInt(value.min)){
                    sortProduct.push(item)
                }
            })
            return sortProduct
        }
        product.Products.forEach((item, index)=>{
            if(parseInt(item.release) >= parseInt(value.min) && parseInt(item.release) <= parseInt(value.max)){
                sortProduct.push(item)
            }
        })
        return sortProduct
    }, [product.Products, value])

    const sort = ({min, max}) =>{
        setValue({min: min, max: max})
        setPage(8)
    }
    const more = () =>{
        setPage(page+8)
    }
    let productFakeObjects = []
    for(let i = 0; i<page; i += 1){
        productFakeObjects.push(i)
    }
    const [popup, setPopup] = useState({visible: false, productText: "", status: 0, cost: 0, imageUrl: ""})

    return (
        <div id='catalog' className="product_container" ref={scroll}>
            <Send visible={popup.visible} status={popup.status} productText={popup.productText} set={setPopup} cost={popup.cost} imageUrl={popup.imageUrl}/>
            <div className={styles.row}>
                <Sort min={min} max={max} callback={sort}/>
                <h2>Выберите год выпуска</h2>
            </div>

            <div style={{display: "flex", flexWrap: 'wrap', marginRight: "-10px",  marginLeft: "-10px"}} className={styles.items}>
                <picture className={styles.hand}>
                    <source srcSet="/img/catalog/handx2.png 2x, /img/catalog/hand.png 1x" />
                    <img src="/img/catalog/hand.png" alt="Bar" />
                </picture>
                {product.Products.length
                    ?sorted.map((item, index) =>
                        page>index && <ProductArticle key={item.id} id={item.id} title={item.title} image={item?.images} cost={item.cost} popup={setPopup}/>
                    )
                    :productFakeObjects.map(index =>
                        <MyLoader key={index} className={stylesArticle.article}/>
                    )
                }

            </div>
            {
                 sorted.length > page && <div className={styles.row}>
                    <button className={styles.btn} onClick={more}>показать еще {sorted.length - page}</button>
                </div>
            }

        </div>
    );




});

export default productList;