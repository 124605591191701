import Main from "./pages/MainPage";
import ProductPage from "./pages/ProductPage";
import loginPage from "./pages/LoginPage";
import OrderPage from "./pages/OrderPage";
import EditPage from "./pages/EditPage";
export const authRoutes = [
    {
        path: "/orders",
        Component: OrderPage
    },
    {
        path: "/edit",
        Component: EditPage
    },
]

export const publicRoutes = [
    {
        path: '/',
        Component: Main
    },
    {
        path: '/product/:id',
        Component: ProductPage
    },
    {
        path: '/login',
        Component: loginPage
    }
]