import AppRouter from "./components/AppRouter";
import {BrowserRouter} from "react-router-dom";
import {useContext, useEffect} from "react";
import {Context} from "./index";
import {check} from "./http/userAPI";
import {getProducts} from "./http/productAPI";
import 'draft-js/dist/Draft.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
function App() {
    const {user, product} = useContext(Context)
    useEffect(() => {
        const checkFirst = async() =>{
            try{
                check().then(data=>{
                    user.setUser(data)
                    user.setIsAuth(true)
                }).finally()
                getProducts().then(data=>{
                    product.setProducts(data)
                }).finally()

            }catch (e) {
                console.log(e)
            }
        }
        checkFirst()

    }, [user]);

    return (
        <div className="App">
            <div className="wrapper">
                <BrowserRouter>
                    <AppRouter/>
                </BrowserRouter>
            </div>
        </div>
    );
}

export default App;
