import React, {useEffect, useState} from 'react';
import styles from "./Feedback.module.css"
import InputMask from 'react-input-mask';
import Send from "../../UI/popups/send";
import {createOrder, sendApplicationWebiraiBot} from "../../../http/orderAPI";
const Feedback = () => {
    const [form, setForm] = useState({name: "", phone: ""})
    const [btnClass, setBrnClass] = useState([styles.btn])
    const [popup, setPopup] = useState({visible: false, productText: "", status: 0})
    useEffect(()=>{
        if(form.phone.replace(/[^0-9]/g, "").length === 11){
            setBrnClass([styles.btn, styles.btnActive])
        }else{
            setBrnClass([styles.btn])
        }
    }, [form])
    const send = async () =>{
        const order = new FormData();
        order.append('name', form.name);
        order.append('phone', form.phone);
        order.append('cost', "");
        order.append('product', "");
        createOrder(order).then(data=>{
            if(data === "success"){
                setPopup({...popup, visible:true, status:1})
                setForm({name: "", phone: ""})
                try{
                    sendApplicationWebiraiBot(`<b>--- Новая Заявка ---</b>\n <b>Имя:</b> ${form.name} \n <b>Телефон:</b>/ ${form.phone}`)
                }catch (e){
                    console.log(e)
                }
            }
        })
    }
    return (
        <div className={styles.block}>
            <Send visible={popup.visible} status={popup.status} productText={popup.productText} set={setPopup}/>
            <div className={styles.container}>
                <h2 className={styles.column}>
                    <div className={styles.row}>
                        <img src="/img/feedback/arrows.svg" alt=""/>
                        <span>Бесплатно</span>
                    </div>
                    <div className={styles.row}>
                        <picture>
                            <source srcSet="/img/feedback/imagesx4.png 4x, /img/feedback/imagesx2.png 2x, /img/feedback/images.png 1x" />
                            <img src="/img/feedback/images.png" alt="" />
                        </picture>
                        <span>дадим</span>
                    </div>
                    <div className={styles.row}>
                        <span>консультацию</span>
                    </div>
                </h2>
                <div className={styles.column}>
                    <input className={styles.input} placeholder="Ваше имя" value={form.name} onChange={e=>setForm({...form, name: e.target.value})}/>
                    <InputMask className={styles.input} mask="+7 999 999 99 99" maskChar=" " value={form.phone} placeholder="+7 ___ ___ __ __" onChange={e=>setForm({...form, phone: e.target.value})}/>
                    <button className={btnClass.join(" ")} onClick={send}>хочу консультацию</button>
                </div>
                <picture className={styles.magazine}>
                    <source srcSet="/img/feedback/magazinex1.png 1x /img/feedback/magazinex2.png 2x"/>
                    <img src="/img/feedback/magazinex2.png" alt="Bar"/>
                </picture>
            </div>
        </div>
    );
};

export default Feedback;