import React, {useEffect, useState} from 'react';
import popupStyles from "./popups.module.css"
import sendStyles from "./send.module.css"
import {useScrollLock} from "../../../hooks";
import InputMask from "react-input-mask";
import {createOrder, sendApplicationWebiraiBot, sendOrderWebiraiBot} from "../../../http/orderAPI";
const Send = ({status, productText, visible, set, cost, imageUrl}) => {
    const {lockScroll, unlockScroll} = useScrollLock();
    const [form, setForm] = useState({name: "", phone: ""})
    const [btnClass, setBrnClass] = useState([sendStyles.btn])
    const rootClass = [popupStyles.modal]
    if(visible){
        rootClass.push(popupStyles.modalActive)
        lockScroll()
    }else{
        unlockScroll()
    }

    useEffect(()=>{
        if(form.phone.replace(/[^0-9]/g, "").length === 11){
            setBrnClass([sendStyles.btn, sendStyles.btnActive])
        }else{
            setBrnClass([sendStyles.btn])
        }
    }, [form])
    const close = () =>{
        set({productText: "",visible: false, status:0})

    }
    const click = async () =>{
        const order = new FormData();
        order.append('name', form.name);
        order.append('phone', form.phone);
        order.append('cost', cost);
        order.append('product', productText);
        createOrder(order).then(data=>{
            if(data === "success"){
                set({productText: "",visible: true, status:1})
                setForm({name: "", phone: ""})
                if(cost){
                    try{
                        sendOrderWebiraiBot(`<b>--- Новый Заказ ---</b>\n <b>Имя:</b> ${form.name} \n <b>Телефон:</b> ${form.phone} \n <b>Сумма:</b> ${cost}р \n <b>Товар:</b> ${productText}`, imageUrl)
                    }catch (e){
                        console.log(e)
                    }
                }else{
                    try{
                        sendApplicationWebiraiBot(`<b>--- Новая Заявка ---</b>\n Имя: ${form.name} \n Телефон: ${form.phone}`)
                    }catch (e){
                        console.log(e)
                    }
                }
            }
        })
    }
    return (
        <div className={rootClass.join(" ")}>
            <div className={popupStyles.wrapper} onClick={close}>
                <div className={popupStyles.body} onClick={e=> e.stopPropagation()}>
                    <div className={popupStyles.close} onClick={close}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                            <path d="M1 1L20 20" stroke="#3E3E3E" strokeWidth="2" strokeLinecap="round"/>
                            <path d="M20 1L1 20" stroke="#3E3E3E" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </div>
                    {status
                        ? <>
                            <div className={sendStyles.title}>Спасибо за заявку</div>
                            <div className={sendStyles.productText}>Мы свяжемся с вами и обудим детали заказа!</div>
                            <svg style={{marginBottom: "64px"}} xmlns="http://www.w3.org/2000/svg" width="142" height="142" viewBox="0 0 142 142" fill="none">
                                <circle cx="71" cy="71" r="70" stroke="#E2E2E2" strokeWidth="2"/>
                                <path d="M40 73.5L59.5 93L81 71.5L102.5 50" stroke="#48E100" strokeWidth="3" strokeLinecap="round"/>
                            </svg>
                        </>
                        : <>
                            <div className={sendStyles.title}>Оформить заявку</div>
                            <div className={sendStyles.productText}>{productText}</div>
                            <input className={sendStyles.input} placeholder="Ваше имя" value={form.name} onChange={e=>setForm({...form, name: e.target.value})}/><br/>
                            <InputMask className={sendStyles.input} mask="+7 999 999 99 99" maskChar=" " value={form.phone} placeholder="+7 ___ ___ __ __" onChange={e=>setForm({...form, phone: e.target.value})}/><br/>
                            <button className={btnClass.join(" ")} onClick={click}>Отправить</button>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default Send;