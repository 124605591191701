import {makeAutoObservable} from "mobx";

export default class ProductStore{
    constructor() {
        this._product = []
        makeAutoObservable(this)
    }

    setProducts(product){
        this._product = product
    }

    get Products(){
        return this._product
    }

}