import React, {useContext, useEffect, useState} from 'react';
import NavAdmin from "../components/navAdmin/NavAdmin";
import stylesAll from "./EditPage.module.css"
import stylesEdit from "./EditPageProduct.module.css"
import {Context} from "../index";
import Notify from "../components/notify/Notify";
import {observer} from "mobx-react-lite";
import {delProducts, getProducts, newProduct, updateProducts} from "../http/productAPI";
import {Editor} from "react-draft-wysiwyg"
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import {stateToHTML} from "draft-js-export-html";
import {convertFromHTML} from "draft-convert";
import DeletePopup from "../components/UI/popups/deletePopup";

const EditPage = observer(() => {
    const [editorState, setEditorState] = React.useState(
        () => EditorState.createEmpty(),
    );
    const onEditorStateChange = (editorState) =>{
        setEditorState(editorState)
        setEditProduct({...editProduct, description: convertToRaw(editorState.getCurrentContent()).blocks[0].text})
    }
    const [notify, setNotify] = useState({text: "", status: "", notify: false})
    const [preview, setPreview] = useState([])
    const {product} = useContext(Context);
    const [imageUrl, setImageUrl] = useState([])
    const [editProduct, setEditProduct] = useState({id: -1, title: "", cost: "", release: "", description: "", images: [], newImages: []})
    const [newBtnClass, setNewBtnClass] = useState([stylesEdit.save])
    const [popupDelete, setPopupDelete] = useState({visible: false, id: -1})
    useEffect(() => {
        getProducts().then(data=>{
            product.setProducts(data.reverse())
        })
        if(editProduct.id >= 0) {
             setEditorState(EditorState.createWithContent(convertFromHTML(editProduct.description)))
        }
    }, [editProduct.id, product]);

    useEffect(() => {
        if(editProduct.title !== '' && editProduct.cost !== '' && editProduct.release !== '' && editProduct.description !== '' && (preview.length !== 0 || editProduct.images.length !== 0)){
            setNewBtnClass([stylesEdit.save, stylesEdit.saveActive])
        }else{
            setNewBtnClass([stylesEdit.save])
        }
    },[editProduct, preview]);
    const delImageOld = (id) =>{
        let images = []
        editProduct.images.forEach((item, preview)=>{
            if(preview!==id){
                images.push(item)
            }
        })
        setEditProduct({...editProduct, images: images})
    }
    const delImageNew = (id) =>{
        let images = []
        let url = []
        preview.forEach((item, preview)=>{
            if(preview!==id){
                images.push(item)
            }
        })
        imageUrl.forEach((item, preview)=>{
            if(preview!==id){
                url.push(item)
            }
        })
        setImageUrl(url)
        setPreview(images)
    }
    const del = (id) =>{
        try {
            delProducts(id).then(data=>{
                getProducts().then(data=>{
                    product.setProducts(data.reverse())
                })
                if(data === "success" ){
                    if(notify.notify === false){
                        setNotify({...notify, text: "Товар успешно удален", status: "success", notify: true})
                        setTimeout(()=>setNotify({...notify, notify: false}), 2000)
                    }

                }else{
                    setNotify({...notify, text: "Ошибка, попробуйте позже!", status: "error", notify: true})
                    setTimeout(()=>setNotify({...notify, notify: false}), 2000)
                }

            });

        }catch(e){
            console.log(e.message)
        }
    }

    const onSelectFile = (e) =>{
        let files = Array.from(e.target.files);
        files.length > 0 && files.forEach((image, index)=> {
            setPreview(preview=>[...preview, image])
            let url = URL.createObjectURL(image)
            setImageUrl(imageUrl=>[...imageUrl, url])
        })
    }
const updateProduct = () =>{
        setPreview([]);
        setImageUrl([]);
        let arr = ({blocks:[{key:"2f3as",text:"",type:"unstyled",depth:0,inlineStyleRanges:[],entityRanges:[],data:{}}],entityMap:{}})
        setEditorState(EditorState.createWithContent(convertFromRaw(arr)))
        const contentState = editorState.getCurrentContent();
        try {
            let product = new FormData()
            product.append("id", editProduct.id)
            product.append("title", editProduct.title)
            product.append("description", stateToHTML(contentState))
            product.append("cost", editProduct.cost)
            product.append("release", editProduct.release)
            product.append("images", JSON.stringify(editProduct.images))
            preview.forEach((item, index)=>{
                product.append(index, item)
            })
            updateProducts(product).then(data=>{
                console.log(data)
                if(data === "success" && notify.notify === false){
                    setNotify({...notify, text: "Товар обновлен", status: "success", notify: true})
                    setEditProduct({...editProduct, id: -1})
                    setTimeout(()=>setNotify({...notify, notify: false}), 2000)
                }
                setEditProduct({
                    ...editProduct,
                    id: -1,
                    title: "",
                    cost: "",
                    release: "",
                    description: "",
                    images: [],
                    newImages: []
                })
            })
        }catch (e){
            console.log(e.message)
        }
    }
const createProduct = () =>{
    setPreview([])
    setImageUrl([])
    let arr = ({blocks:[{key:"2f3as",text:"",type:"unstyled",depth:0,inlineStyleRanges:[],entityRanges:[],data:{}}],entityMap:{}})
    setEditorState(EditorState.createWithContent(convertFromRaw(arr)))
    try {
        const contentState = editorState.getCurrentContent();
        let addProduct = new FormData()
        addProduct.append("title", editProduct.title)
        addProduct.append("description", stateToHTML(contentState))
        addProduct.append("cost", editProduct.cost)
        addProduct.append("release", editProduct.release)
        addProduct.append("images", editProduct.images)
        preview.forEach((item, index)=>{
            addProduct.append(index, item)
        })
        newProduct(addProduct).then(data=>{
            if(data === "success" && notify.notify === false){
                setNotify({...notify, text: "Товар создан", status: "success", notify: true})
                setEditProduct({...editProduct, id: -1})
                setTimeout(()=>setNotify({...notify, notify: false}), 2000)
            }
            setEditProduct({
                ...editProduct,
                id: -1,
                title: "",
                cost: "",
                release: "",
                description: "",
                images: [],
                newImages: []
            })
        })
    }catch (e){
        console.log(e.message)
    }
}
const back = () => {
    setPreview([])
    setImageUrl([])
    setEditProduct({
        ...editProduct,
        id: -1,
        title: "",
        cost: "",
        release: "",
        description: "",
        images: [],
        newImages: []
    })
    let arr = ({blocks:[{key:"2f3as",text:"",type:"unstyled",depth:0,inlineStyleRanges:[],entityRanges:[],data:{}}],entityMap:{}})
    setEditorState(EditorState.createWithContent(convertFromRaw(arr)))
}
    return (
        <div style={{background: "#141414"}}>
            <div>
                <NavAdmin/>
            </div>
            <Notify text={notify.text} notify={notify.notify} status={notify.status}/>
            <DeletePopup set={setPopupDelete} visible={popupDelete.visible} del={del} id={popupDelete.id}/>

            <div style={{margin: "100px 80px 100px 431px", minHeight: "calc(100dvh - 200px)"}}>
                {
                    editProduct.id===-1 &&  <>
                    <div className={stylesAll.new}><button onClick={()=>setEditProduct({...editProduct, id: -2})}>Добавить товар</button></div>
                    <div className={stylesAll.items}>
                            <div className={stylesAll.item + " " + stylesAll.itemTitle} >
                                <div className={stylesAll.id}>ID</div>
                                <div className={stylesAll.image}>Изображение</div>
                                <div className={stylesAll.name}>Название</div>
                                <div className={stylesAll.price}>Цена</div>
                                <div className={stylesAll.actions}>Действия</div>
                            </div>
                            {product && product.Products.map(product =>
                                <div className={stylesAll.item} key={product.id} onClick={()=>setEditProduct({...editProduct, id: product.id, title: product.title, cost: product.cost, release: product.release, description: product.description, images: product.images})}>
                                    <div className={stylesAll.id}>{product.id}</div>
                                    <div className={stylesAll.image}><img src={product.images[0].src} alt=""/></div>
                                    <div className={stylesAll.name}>{product.title}</div>
                                    <div className={stylesAll.price}>{product.cost}₽</div>
                                    <div className={stylesAll.actions}>
                                        <button className={stylesAll.actionsBtn + " " + stylesAll.actionsBtnEdit} onClick={()=>setEditProduct({...editProduct, id: product.id, title: product.title, cost: product.cost, release: product.release, description: product.description, images: product.images})}>
                                            <svg width="17" height="18"  viewBox="0 0 17 18" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.02628 11.9397L0.0250553 16.5794C-0.00948348 16.749 -0.00829947 16.9247 0.0285207 17.0938C0.065341 17.2628 0.136867 17.4209 0.237875 17.5565C0.338882 17.692 0.466819 17.8016 0.612338 17.8773C0.757858 17.953 0.917285 17.9928 1.07897 17.9939C1.15431 18.002 1.23023 18.002 1.30556 17.9939L5.65297 16.9188L14 7.99041L9.34169 3L1.02628 11.9397Z"/>
                                                <path d="M16.6627 3.58055L13.4139 0.331806C13.2003 0.119296 12.9113 0 12.61 0C12.3087 0 12.0197 0.119296 11.8061 0.331806L10 2.13789L14.8621 7L16.6682 5.19392C16.7739 5.08769 16.8576 4.96166 16.9145 4.82304C16.9715 4.68443 17.0005 4.53595 17 4.38609C16.9995 4.23624 16.9694 4.08796 16.9115 3.94974C16.8537 3.81151 16.7691 3.68606 16.6627 3.58055Z"/>
                                            </svg>
                                        </button>
                                        <button className={[stylesAll.actionsBtn, stylesAll.actionsBtnDelete].join(" ")} onClick={e=> {
                                            e.stopPropagation();
                                            setPopupDelete({...popupDelete, id: product.id, visible: true})
                                        }}>
                                            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.2 3.6H10.8C10.8 3.12261 10.6104 2.66477 10.2728 2.32721C9.93523 1.98964 9.47739 1.8 9 1.8C8.52261 1.8 8.06477 1.98964 7.72721 2.32721C7.38964 2.66477 7.2 3.12261 7.2 3.6ZM5.4 3.6C5.4 2.64522 5.77928 1.72955 6.45442 1.05442C7.12955 0.379285 8.04522 0 9 0C9.95478 0 10.8705 0.379285 11.5456 1.05442C12.2207 1.72955 12.6 2.64522 12.6 3.6H17.1C17.3387 3.6 17.5676 3.69482 17.7364 3.8636C17.9052 4.03239 18 4.2613 18 4.5C18 4.73869 17.9052 4.96761 17.7364 5.1364C17.5676 5.30518 17.3387 5.4 17.1 5.4H16.3062L15.5088 14.706C15.4321 15.6046 15.021 16.4417 14.3567 17.0517C13.6924 17.6617 12.8233 18.0001 11.9214 18H6.0786C5.17672 18.0001 4.30765 17.6617 3.64333 17.0517C2.97902 16.4417 2.56786 15.6046 2.4912 14.706L1.6938 5.4H0.9C0.661305 5.4 0.432387 5.30518 0.263604 5.1364C0.0948211 4.96761 0 4.73869 0 4.5C0 4.2613 0.0948211 4.03239 0.263604 3.8636C0.432387 3.69482 0.661305 3.6 0.9 3.6H5.4ZM11.7 9C11.7 8.7613 11.6052 8.53239 11.4364 8.3636C11.2676 8.19482 11.0387 8.1 10.8 8.1C10.5613 8.1 10.3324 8.19482 10.1636 8.3636C9.99482 8.53239 9.9 8.7613 9.9 9V12.6C9.9 12.8387 9.99482 13.0676 10.1636 13.2364C10.3324 13.4052 10.5613 13.5 10.8 13.5C11.0387 13.5 11.2676 13.4052 11.4364 13.2364C11.6052 13.0676 11.7 12.8387 11.7 12.6V9ZM7.2 8.1C6.96131 8.1 6.73239 8.19482 6.5636 8.3636C6.39482 8.53239 6.3 8.7613 6.3 9V12.6C6.3 12.8387 6.39482 13.0676 6.5636 13.2364C6.73239 13.4052 6.96131 13.5 7.2 13.5C7.43869 13.5 7.66761 13.4052 7.8364 13.2364C8.00518 13.0676 8.1 12.8387 8.1 12.6V9C8.1 8.7613 8.00518 8.53239 7.8364 8.3636C7.66761 8.19482 7.43869 8.1 7.2 8.1Z"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                }
                { editProduct.id >= 0 &&
                    <div>
                        <div className={stylesEdit.row} style={{marginBottom: "80px"}}>
                            <div className={stylesEdit.title}>Редактирование товара</div>
                            <div className={stylesEdit.back} onClick={back}>Назад к товарам</div>
                        </div>
                        <div className={stylesEdit.row}>
                            <div className={stylesEdit.itemText}>
                                <div className={stylesEdit.itemTextTitle}>ID</div>
                                <input className={stylesEdit.itemTextInput} disabled value={editProduct.id}/>
                            </div>
                            <div className={stylesEdit.itemText}>
                                <div className={stylesEdit.itemTextTitle}>Название</div>
                                <input className={stylesEdit.itemTextInput} value={editProduct.title} style={{width: "500px"}} onChange={e=>setEditProduct({...editProduct, title: e.target.value})}/>
                            </div>
                            <div className={stylesEdit.itemText}>
                                <div className={stylesEdit.itemTextTitle}>Цена</div>
                                <input className={stylesEdit.itemTextInput} value={editProduct.cost} onChange={e=>setEditProduct({...editProduct, cost: e.target.value})}/>
                            </div>
                            <div className={stylesEdit.itemText}>
                                <div className={stylesEdit.itemTextTitle}>Год</div>
                                <input className={stylesEdit.itemTextInput} value={editProduct.release} onChange={e=>setEditProduct({...editProduct, release: e.target.value})}/>
                            </div>
                        </div>
                        <div className={stylesEdit.itemTextTitle} style={{marginTop: "50px"}}>Изображения</div>
                        <div className={[stylesEdit.row, stylesEdit.rowImages].join(" ")}>
                            {editProduct.images.length>0 && editProduct.images.map((preview, index)=>
                                <div className={stylesEdit.itemImg} key={preview.src}>
                                    <div className={stylesEdit.itemImgWrapper}><img src={preview.src} alt=""/></div>
                                    <div className={stylesEdit.itemImgRow}>
                                        <div className={stylesEdit.itemImgId}>{index+1}</div>
                                        <div className={stylesEdit.itemImgDel} onClick={e=>{delImageOld(index)}}>Удалить</div>
                                    </div>
                                </div>
                            )}
                            {imageUrl.length>0 && imageUrl.map((item, index)=>
                                <div className={stylesEdit.itemImg} key={item}>
                                    <div className={stylesEdit.itemImgWrapper}><img src={item} alt=""/></div>
                                    <div className={stylesEdit.itemImgRow}>
                                        <div className={stylesEdit.itemImgId}>{editProduct.images.length+index+1}</div>
                                        <div className={stylesEdit.itemImgDel} onClick={e=> {delImageNew(index)}}>Удалить</div>
                                    </div>
                                </div>
                            )}
                            <div className={stylesEdit.newImage}>
                                <input type='file' className={stylesEdit.newImageInput} multiple="multiple" onChange={e=> onSelectFile(e)} />
                                <div className={stylesEdit.newImageImage}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63" fill="none">
                                        <circle cx="31.5" cy="31.5" r="30.5" stroke="#505050" strokeWidth="2"/>
                                        <path d="M31 21V43" stroke="#505050" strokeWidth="2"/>
                                        <path d="M42 32H20" stroke="#505050" strokeWidth="2"/>
                                    </svg>
                                </div>
                                <div className={stylesEdit.newImageTitle}>Добавить фото</div>
                            </div>
                        </div>
                        <div style={{background: "#fff", maxWidth: "1000px"}}>
                            <Editor  editorState={editorState} toolbarCalssName="toolbarCalssName" wrapperClassName="wrapperClassName" toolbar={{options: ['inline', 'link']}} editorClassName="editorClassName" onEditorStateChange={onEditorStateChange} />
                        </div>
                        <button className={newBtnClass.join(" ")} onClick={updateProduct}>Сохранить</button>
                    </div>
                }
                {
                    editProduct.id===-2 &&
                    <div>
                        <div className={stylesEdit.row} style={{marginBottom: "80px"}}>
                            <div className={stylesEdit.title}>Добавить товар</div>
                            <div className={stylesEdit.back} onClick={back}>Назад к товарам</div>
                        </div>
                        <div className={stylesEdit.row}>
                            <div className={stylesEdit.itemText}>
                                <div className={stylesEdit.itemTextTitle}>Название</div>
                                <input className={stylesEdit.itemTextInput} value={editProduct.title} style={{width: "500px"}} onChange={e=>setEditProduct({...editProduct, title: e.target.value})}/>
                            </div>
                            <div className={stylesEdit.itemText}>
                                <div className={stylesEdit.itemTextTitle}>Цена</div>
                                <input className={stylesEdit.itemTextInput} value={editProduct.cost} onChange={e=>setEditProduct({...editProduct, cost: e.target.value})}/>
                            </div>
                            <div className={stylesEdit.itemText}>
                                <div className={stylesEdit.itemTextTitle}>Год</div>
                                <input className={stylesEdit.itemTextInput} value={editProduct.release} onChange={e=>setEditProduct({...editProduct, release: e.target.value})}/>
                            </div>
                        </div>
                        <div className={stylesEdit.itemTextTitle} style={{marginTop: "50px"}}>Изображения</div>
                        <div className={[stylesEdit.row, stylesEdit.rowImages].join(" ")}>
                            {imageUrl.length>0 && imageUrl.map((item, index)=>
                                <div className={stylesEdit.itemImg} key={item}>
                                    <div className={stylesEdit.itemImgWrapper}><img src={item} alt=""/></div>
                                    <div className={stylesEdit.itemImgRow}>
                                        <div className={stylesEdit.itemImgId}>{editProduct.images.length+index+1}</div>
                                        <div className={stylesEdit.itemImgDel} onClick={e=> {delImageNew(index)}}>Удалить</div>
                                    </div>
                                </div>
                            )}
                            <div className={stylesEdit.newImage}>
                                <input type='file' className={stylesEdit.newImageInput} multiple="multiple" onChange={e=> onSelectFile(e)} />
                                <div className={stylesEdit.newImageImage}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63" fill="none">
                                        <circle cx="31.5" cy="31.5" r="30.5" stroke="#505050" strokeWidth="2"/>
                                        <path d="M31 21V43" stroke="#505050" strokeWidth="2"/>
                                        <path d="M42 32H20" stroke="#505050" strokeWidth="2"/>
                                    </svg>
                                </div>
                                <div className={stylesEdit.newImageTitle}>Добавить фото</div>
                            </div>
                        </div>
                        <div style={{background: "#fff", maxWidth: "1000px"}}>
                            <Editor  editorState={editorState} toolbarCalssName="toolbarCalssName" wrapperClassName="wrapperClassName" editorClassName="editorClassName" toolbar={{options: ['inline', 'link']}} onEditorStateChange={onEditorStateChange} />
                        </div>
                        <button className={newBtnClass.join(" ")} onClick={createProduct}>Сохранить</button>
                    </div>
                }
            </div>
        </div>
    );
});

export default EditPage;