import React, {useState} from 'react';
import styles from "./AboutAs.module.css"
import Send from "../../UI/popups/send";
import scroll from "react-scroll";
const AboutUs = () => {
    const [popup, setPopup] = useState({visible: false, productText: "", status: 0, cost: 0})
    const click = () =>{
        setPopup({...popup, visible: true, productText: "", cost: ""})
    }
    return (
        <div className={styles.block}>
            <picture className={styles.bag}>
                <source srcSet="/img/aboutUs/bagx1.png 1x /img/aboutUs/bagx2.png 2x"/>
                <img src="/img/aboutUs/bagx2.png" alt="Bar"/>
            </picture>
            <Send visible={popup.visible} status={popup.status} productText={popup.productText} set={setPopup} cost={popup.cost}/>
            <div className={styles.container}>
                <div className={styles.top}>
                    <div className={styles.topText}>
                        Integrity Toys демонстрирует различные направления высокой моды в одежде и аксессуарах, что полностью изменило индустрию и вывело ее на новый уровень. Эта концепция завоевала популярность и востребованность во всем мире, и с момента ее появления коллекционеры с нетерпением ждали предзаказа на новинки каждый год
                    </div>
                    <button className={styles.topBtn} onClick={click}>хочу консультацию</button>
                    <div className={styles.topImg}>
                        <img  src="/img/aboutUs/1.png" alt=""/>
                    </div>
                    <picture className={styles.wallet}>
                        <source srcSet="/img/aboutUs/walletx1.png 1x /img/aboutUs/walletx2.png 2x"/>
                        <img src="/img/aboutUs/walletx2.png" alt="Bar"/>
                    </picture>

                </div>
                <div className={styles.medium}>
                    <div className={styles.mediumColumn}>
                        <div className={styles.mediumGetDoll}>
                            <picture>
                                <source srcSet="/img/aboutUs/3x2.png 4x, /img/aboutUs/3.png 1x" />
                                <img src="/img/aboutUs/3.png" alt="Bar" />
                            </picture>
                            <h2 className={styles.mediumTitle}>Почему Getdoll?</h2>
                        </div>
                    </div>
                    <div className={styles.mediumColumn}>
                        <div className={styles.mediumWrapper}>
                            <div className={styles.mediumItem}>
                                <picture>
                                    <source srcSet="/img/aboutUs/2x2.png 2x, /img/aboutUs/2.png 1x" />
                                    <img src="/img/aboutUs/2.png" alt="Bar" />
                                </picture>
                                <h2 className={styles.mediumItemTitle}>100% оригинальные куклы</h2>
                                <h3 className={styles.mediumItemText}>Предлагаем купить только оригинальные куклы и игрушки - ЛОЛ, Единороги Пупси, Монстер Хай, Барби, Пуллип, Эвер Афтер Хай, реборны, Винкс и многие другие. Качество гарантировано производителями.</h3>
                                <picture className={styles.glasses}>
                                    <source srcSet="/img/aboutUs/glassesx1.png 1x /img/aboutUs/glassesx2.png 2x"/>
                                    <img src="/img/aboutUs/glassesx1.png" alt="glasses"/>
                                </picture>
                            </div>
                        </div>
                        <div className={styles.mediumWrapper}>
                            <div className={styles.mediumItem}>
                                <picture>
                                    <source srcSet="/img/aboutUs/4x2.png 2x, /img/aboutUs/4.png 1x" />
                                    <img src="/img/aboutUs/4.png" alt="Bar" />
                                </picture>
                                <h2 className={styles.mediumItemTitle}>Заказ в любое время суток</h2>
                                <h3 className={styles.mediumItemText}>Вы можете оформить заказ ежедневно, в любое время через интернет-магазин и 7 дней в неделю по телефону.</h3>
                            </div>
                        </div>
                        <div className={styles.mediumWrapper}>
                            <div className={styles.mediumItem}>
                                <picture>
                                    <source srcSet="/img/aboutUs/5x2.png 2x, /img/aboutUs/5.png 1x" />
                                    <img src="/img/aboutUs/5.png" alt="Bar" />
                                </picture>
                                <h2 className={styles.mediumItemTitle}>Доставка по всему снг</h2>
                                <h3 className={styles.mediumItemText}>Выберите и закажите куклу, а мы доставим в любую точку России и СНГ самым удобным для вас способом</h3>
                                <picture className={styles.case}>
                                    <source srcSet="/img/aboutUs/casex1.png 1x /img/aboutUs/casex2.png 2x"/>
                                    <img src="/img/aboutUs/casex1.png" alt="glasses"/>
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.bottom}>
                    <div className={styles.bottomColumn}>
                        <h2 className={styles.bottomItem}>
                            Добро пожаловать в Getdoll, интернет-магазин, созданный специально для коллекционеров и любителей кукол, кукольных миниатюр и декора!
                        </h2>
                        <h3 className={styles.bottomItem}>
                            В Getdoll мы стремимся помочь коллекционерам и любителям кукол осуществить их мечты. Если вы ищете конкретную куклу или миниатюру, чтобы пополнить свою коллекцию, просто напишите нам, и мы сделаем все возможное, чтобы помочь вам. Мы предлагаем только оригинальные куклы , включая редкие и лимитированные коллекционные экземпляры.  Для нас важна каждая деталь - от прекрасной мимики до изысканных тканей и швов. Мы стремимся предложить вам самое лучшее.
                            <picture className={styles.shoe2}>
                                <source srcSet="/img/aboutUs/shoe2x2.png 2x, /img/aboutUs/shoe2x1.png 1x" />
                                <img src="/img/aboutUs/shoe2x1.png" alt="Bar" />
                            </picture>
                        </h3>
                    </div>
                    <div className={styles.bottomColumn}>
                        <h3 className={styles.bottomItem}>
                            В нашем магазине представлен широкий ассортимент кукол Integrity Toys начала 2000-х годов и выше, Мы также предлагаем необычный американский декор для кукол и оригинальные украшения по доступным ценам в Москве.
                            <picture className={styles.shoe1}>
                                <source srcSet="/img/aboutUs/shoe1x2.png 2x, /img/aboutUs/shoe1x1.png 1x" />
                                <img src="/img/aboutUs/shoe1x1.png" alt="Bar" />
                            </picture>
                        </h3>
                        <div className={styles.bottomItem}>
                            <h3>Integrity Toys - это работа группы преданных, вдохновленных и любящих друзей и членов семьи. Каждая кукла - это воплощение стандартов нашей компании, стремящейся предоставить лучший в мире и уникальный выбор.</h3>
                            <scroll.Link activeClass="active" to="catalog" spy={true} smooth={true} offset={-50} duration={1000}><button className={styles.bottomBtn}>перейти в каталог</button></scroll.Link>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default AboutUs;