import React from 'react';
import popupStyles from "./popups.module.css"
import popupDelete from "./delete.module.css"
import {useScrollLock} from "../../../hooks";
const DeletePopup = ({visible, set, del, id}) => {
    const {lockScroll, unlockScroll} = useScrollLock();
    const close = () =>{
        set({productText: "",visible: false, status:0})
    }
    const click = () =>{
        set({productText: "",visible: false, status:0})
        del(id)
    }
    const rootClass = [popupStyles.modal]
    if(visible){
        rootClass.push(popupStyles.modalActive)
        lockScroll()
    }else{
        unlockScroll()
    }
    return (
        <div>
            <div className={rootClass.join(" ")}>
                <div className={popupStyles.wrapper} onClick={close}>
                    <div className={popupDelete.body} onClick={e=>e.stopPropagation()}>
                        <div className={popupDelete.title}>Вы точно хотите удалить данный товар?</div>
                        <div className={popupDelete.row}>
                            <button className={[popupDelete.btn, popupDelete.btnGreen].join(" ")} onClick={click}>Да</button>
                            <button className={[popupDelete.btn, popupDelete.btnRed].join(" ")} onClick={close}>Нет</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeletePopup;