import React, {useEffect, useRef, useState} from 'react';
import styles from './productArticle.module.css'
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
const productArticle = observer( ({id, title, image, cost, popup}) => {
    const navigate = useNavigate()
    const wrapper = useRef()
    const [resize, setResize] = useState()
    const click = () =>{
        navigate(`/product/${id}`)
    }
    const buy = (text, cost) =>{
        let src = image[0]?.src;
        popup({visible:true, status:0, productText: text, cost: cost, imageUrl: src})
    }
    useEffect(()=>{
        wrapper.current.style.height = wrapper.current.offsetWidth * 1.3 + "px"
    }, [resize])

    window.addEventListener("resize", ()=>{
        setResize(wrapper.current.offsetWidth)
    })

    return (
        <article className={styles.article} onClick={click}>
            <div className={styles.imageWrapper} ref={wrapper}>
                <div><img src={image[0]?.src} alt={title}/></div>
                <div className={styles.buy} onClick={e=>{
                    e.stopPropagation();
                    buy(title,cost);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <path d="M10 8V5H7V3H10V0H12V3H15V5H12V8H10ZM6 21C5.45 21 4.979 20.804 4.587 20.412C4.195 20.02 3.99933 19.5493 4 19C4 18.45 4.196 17.979 4.588 17.587C4.98 17.195 5.45067 16.9993 6 17C6.55 17 7.021 17.196 7.413 17.588C7.805 17.98 8.00067 18.4507 8 19C8 19.55 7.804 20.021 7.412 20.413C7.02 20.805 6.54933 21.0007 6 21ZM16 21C15.45 21 14.979 20.804 14.587 20.412C14.195 20.02 13.9993 19.5493 14 19C14 18.45 14.196 17.979 14.588 17.587C14.98 17.195 15.4507 16.9993 16 17C16.55 17 17.021 17.196 17.413 17.588C17.805 17.98 18.0007 18.4507 18 19C18 19.55 17.804 20.021 17.412 20.413C17.02 20.805 16.5493 21.0007 16 21ZM6 16C5.25 16 4.675 15.6707 4.275 15.012C3.875 14.3533 3.86667 13.6993 4.25 13.05L5.6 10.6L2 3H0V1H3.275L7.525 10H14.55L18.425 3L20.175 3.95L16.3 10.95C16.1167 11.2833 15.875 11.5417 15.575 11.725C15.275 11.9083 14.9333 12 14.55 12H7.1L6 14H18V16H6Z"/>
                    </svg>
                </div>
            </div>
            <div className={styles.title}>{title}</div>
            <div className={styles.cost}>{cost}₽</div>
        </article>
    );
});

export default productArticle;