import {$authHost, $host} from "./index";


export const login = async (login, password) =>{
    const {data} = await $host.post( '/login', JSON.stringify({login, password}));
    return data;
}
export const check = async () =>{
    const {data} = await $authHost.post('/check')
    return data;
}