import React, {useEffect, useState} from 'react';
import styles from "./Notify.module.css"
const Notify = ({notify, text, status}) => {
    const [classStyles, setClassStyles] = useState([styles.body])
    useEffect(()=>{
        setClassStyles([styles.body])
        notify && setClassStyles(classStyles => [...classStyles, styles.bodyActive])
        switch(status){
            case "error":
                setClassStyles(classStyles => [...classStyles, styles.bodyError])
                break;
            case "warning":
                setClassStyles(classStyles => [...classStyles, styles.bodyWarning])
                break;
            case "success":
                setClassStyles(classStyles => [...classStyles, styles.bodySuccess]);
                break;
        }
    }, [notify,status])

    return (
        <div className={classStyles.join(" ")}>
            <div className={styles.text}>{text}</div>
            <div className={styles.progressBar}></div>
        </div>
    );
};

export default Notify;