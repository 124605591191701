import React from 'react';
import styles from "./Gallery.module.css"
const Gallery = () => {
    return (
        <div className={styles.block}>
            <div className={styles.container}>
                <div className={styles.column}>
                    <div className={styles.item}>
                        <picture>
                            <source srcSet="/img/gallery/1x4.png 4x, /img/gallery/1x2.png 2x, /img/gallery/1.png 1x" />
                            <img src="/img/gallery/1.png" alt="" />
                        </picture>
                    </div>
                    <div className={styles.item}>
                        <picture>
                            <source srcSet="/img/gallery/2x4.png 4x, /img/gallery/2x2.png 2x, /img/gallery/2.png 1x" />
                            <img src="/img/gallery/2.png" alt="" />
                        </picture>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.item}>
                        <picture>
                            <source srcSet="/img/gallery/3x4.png 4x, /img/gallery/3x2.png 2x, /img/gallery/3.png 1x" />
                            <img src="/img/gallery/3.png" alt="" />
                        </picture>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.item}>
                        <img src="/img/gallery/4.png" alt=""/>
                    </div>
                    <div className={styles.item}>
                        <img src="/img/gallery/5.png" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Gallery;