import React from 'react';
import styles from "./NevAdmin.module.css"
import {Link, useLocation, useNavigate} from "react-router-dom";
const NavAdmin = () => {
    const {pathname} = useLocation()
    const navigate = useNavigate();
    const logout = () =>{
        navigate("/");
        localStorage.clear();
    }
    return (
        <nav className={styles.nav}>
            <div className={styles.title}>Getdoll</div>
            <ul className={styles.navigate}>
                <li>
                    <svg width="18" height="20" className={pathname === "/edit" ?styles.navigateIconsActive : styles.navigateIcons} viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 20H16C17.103 20 18 19.103 18 18V7C18 6.73478 17.8946 6.48043 17.7071 6.29289C17.5196 6.10536 17.2652 6 17 6H14V5C14 2.243 11.757 0 9 0C6.243 0 4 2.243 4 5V6H1C0.734784 6 0.48043 6.10536 0.292893 6.29289C0.105357 6.48043 0 6.73478 0 7V18C0 19.103 0.897 20 2 20ZM6 5C6 3.346 7.346 2 9 2C10.654 2 12 3.346 12 5V6H6V5ZM2 8H4V10H6V8H12V10H14V8H16L16.002 18H2V8Z"/>
                    </svg>

                    <Link to="/edit">Товары</Link>
                </li>

                <li>
                    <svg width="18" height="16" viewBox="0 0 18 16" className={pathname === "/orders" ?styles.navigateIconsActive : styles.navigateIcons} xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.74999 6.57143V9.42857C4.74999 9.63328 4.5887 9.78572 4.40624 9.78572H0.84375C0.661291 9.78572 0.5 9.63328 0.5 9.42857V6.57143C0.5 6.36673 0.661291 6.21429 0.84375 6.21429H4.40624C4.5887 6.21429 4.74999 6.36673 4.74999 6.57143ZM0.5 15.1429V12.2857C0.5 12.081 0.661291 11.9286 0.84375 11.9286H4.40624C4.5887 11.9286 4.74999 12.081 4.74999 12.2857V15.1429C4.74999 15.3476 4.5887 15.5 4.40624 15.5H0.84375C0.66129 15.5 0.5 15.3476 0.5 15.1429ZM0.84375 0.5H4.40624C4.5887 0.5 4.74999 0.65244 4.74999 0.857143V3.71429C4.74999 3.91899 4.5887 4.07143 4.40624 4.07143H0.84375C0.661291 4.07143 0.5 3.91899 0.5 3.71429V0.857143C0.5 0.65244 0.661291 0.5 0.84375 0.5ZM17.1562 15.5H7.21874C7.03628 15.5 6.87499 15.3476 6.87499 15.1429V12.2857C6.87499 12.081 7.03628 11.9286 7.21874 11.9286H17.1562C17.3387 11.9286 17.5 12.081 17.5 12.2857V15.1429C17.5 15.3476 17.3387 15.5 17.1562 15.5ZM6.87499 3.71429V0.857143C6.87499 0.65244 7.03628 0.5 7.21874 0.5H17.1562C17.3387 0.5 17.5 0.65244 17.5 0.857143V3.71429C17.5 3.91899 17.3387 4.07143 17.1562 4.07143H7.21874C7.03628 4.07143 6.87499 3.91899 6.87499 3.71429ZM17.1562 9.78572H7.21874C7.03628 9.78572 6.87499 9.63328 6.87499 9.42857V6.57143C6.87499 6.36673 7.03628 6.21429 7.21874 6.21429H17.1562C17.3387 6.21429 17.5 6.36673 17.5 6.57143V9.42857C17.5 9.63328 17.3387 9.78572 17.1562 9.78572Z"/>
                    </svg>
                    <Link  to="/orders">Заявки</Link>
                </li>
            </ul>
            <ul className={styles.actions}>
                <li><img src="/img/logout.svg" className="actionsIcons" alt=""/>
                    <Link to="/products" onClick={logout}>Выйти</Link>
                </li>
            </ul>
        </nav>
    );
};

export default NavAdmin;