import {$authHost, $host} from "./index";


export const getProducts = async () =>{
    const {data} = await $host.get( '/products');
    return data;
}

export const getProduct = async (id) =>{
    const {data} = await $host.get( `/product?id=${id}`);
    return data;
}
export const delProducts = async (id) =>{
    const {data} = await $authHost.post( '/product', JSON.stringify({id: id}));
    return data;
}

export const updateProducts = async (product) =>{
    const {data} = await $authHost.post('/update', product)
    return data;
}

export const newProduct = async (product) =>{
    const {data} = await $authHost.post('/createproduct',  product);
    return data;
}