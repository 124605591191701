import React, {useState} from 'react';
import styles from './Sort.module.css'
const Sort =({callback, min, max}) => {
    const [value, setValue] = useState({min: "", max: ""})
    const [startValue, setStartValue] = useState({min: "", max: ""})
    const [activeClass, setActiveClass] = useState(0)

    const changeMin = (e) =>{
        setValue({...value, min: e.target.value})
    }
    const changeMax = (e) =>{
        setValue({...value, max: (e.target.value)})
    }
    const open = () =>{
        setActiveClass(1);
        if(value.min === "" && value.max === ""){
            setValue({min: min, max: max})
            setStartValue({min: min, max: max})
        }
    }
    const close = () =>{
        setActiveClass(0)
    }
    return (
        <div className={[styles.body, "Sort_body"].join(" ")}>
            <div className={styles.selected} onMouseEnter={open} onMouseLeave={close}>
                <div>{(value.min === "" && value.max === "") || value.max === 0
                    ? <span>все</span>
                    : value.min + "-"+ value.max
                }</div>
                <div className={activeClass?styles.dropListActive : styles.dropList}>
                    <div className={styles.dropListItem}>
                        <div className={styles.dropListText}>{value.min}</div>
                        <input type="range" className={styles.dropListInput} min={startValue.min} max={startValue.max} value={value.min} onChange={changeMin}onMouseUp={e=> {callback(value)}} onTouchEnd={e=> {callback(value)}}/>
                    </div>
                    <div className={styles.dropListItem}>
                        <div className={styles.dropListText}>{value.max}</div>
                        <input type="range" className={styles.dropListInput} min={startValue.min} max={startValue.max} value={value.max} onChange={changeMax} onMouseUp={e=> {callback(value)}} onTouchEnd={e=> {callback(value)}}/>
                    </div>
                </div>
            </div>

        </div>
    );
};
export default Sort;