import { Swiper, SwiperSlide} from 'swiper/react';
import {EffectFade, Navigation, Pagination} from 'swiper/modules';
import 'swiper/css';
import "swiper/css/pagination"
import styles from "./slider.module.css"
import {useRef} from "react";
import './pagination.css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
const Slider = ({images}) => {
    const paginationRef = useRef(null)
    return (
        <div className={styles.body}>
            <Swiper

                modules={[Pagination, Navigation, EffectFade]}
                spaceBetween={50}
                slidesPerView={1}
                effect="fade"
                navigation
                pagination={{
                    el: paginationRef.current,
                    clickable: true,

                }}
                className={styles.slider}
            >
                {
                    images.map(item=>
                        <SwiperSlide key={item.id} className={styles.slide}>
                            <img src={item.src} className={styles.img} alt="Товар"/>
                        </SwiperSlide>
                    )
                }
            </Swiper>
            <div ref={paginationRef} className={styles.pagination}></div>
        </div>
    );
};

export default Slider;