import {$authHost, $host} from "./index";
import axios from "axios";


export const getOrders = async (login, password) =>{
    const {data} = await $authHost.get( '/orders');
    return data;
}

export const createOrder = async (order) =>{
    const {data} = await $host.post( '/order', order);
    return data;
}

export const sendApplicationWebiraiBot = async (message) => {
    await axios.post("https://api-bot.webirai.ru/api/order", {
        companyId: 24,
        token: "37b9318b-1510-4cbd-b0d4-8dc985b43fb4",
        message
    })
}

export const sendOrderWebiraiBot = async (message,  images) => {
    await axios.post("https://api-bot.webirai.ru/api/order", {
        companyId: 24,
        token: "37b9318b-1510-4cbd-b0d4-8dc985b43fb4",
        message,
        imageUrl: images
    })
}