import React from 'react';
import styles from "./Main.module.css"
import scroll from "react-scroll";
const Main = ({Link}) => {
    return (
        <div className={styles.block}>
            <div className={styles.container}>
                <div className={styles.column}>
                    <picture>
                        {/*<source srcSet="/img/main/coverx4.png 4x, /img/main/coverx2.png 2x, /img/main/cover.png 1x" />*/}
                        <img src="/img/main/cover.png" alt="cover"/>
                    </picture>
                    <div className={styles.text}>100% Оригинальные куклы</div>
                </div>
                <div className={styles.column}>
                    <div className={styles.title}>
                        <div className={styles.row}>
                            <span>магазин</span>
                            <img className={styles.arrows} src="/img/main/arrows.svg" alt=""/>
                            <picture className={styles.photos}>
                                <source srcSet="/img/main/photosx4.png 4x, /img/main/photosx2.png 2x, /img/main/photos.png 1x" />
                                <img src="/img/main/photos.png" alt="" />
                            </picture>
                        </div>
                        <div className={styles.row}><span>колекционых</span></div>
                        <div className={styles.row}>
                            <span>кукол</span>
                            <picture className={styles.decor}>
                                <source srcSet="/img/main/decorx4.png 4x, /img/main/decorx2.png 2x, /img/main/decor.png 1x" />
                                <img src="/img/main/decor.png" alt="" />
                            </picture>
                        </div>
                    </div>
                    <div className={styles.actions}>
                        <scroll.Link activeClass="active" to="catalog" spy={true} smooth={true} offset={-50} duration={1000}><button className={[styles.button, styles.buttonOrange].join(" ")}>КАТАЛОГ</button></scroll.Link>
                        <a href="tel:79265877151" className={[styles.button, styles.buttonBlue].join(" ")}>ПОЗВОНИТЬ</a>
                        <div className={styles.subText}>Доставка по всему снг</div>
                    </div>

                </div>
            </div>
            <div className={styles.decorCover}>
                <svg className={styles.star} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12.8715 1.42252C13.9446 -0.0926243 16.3234 0.544785 16.4951 2.39345L17.0258 8.10761C17.0798 8.68883 17.385 9.21745 17.8614 9.55481L22.5446 12.8715C24.0598 13.9445 23.4224 16.3234 21.5737 16.4951L15.8595 17.0258C15.2783 17.0798 14.7497 17.385 14.4123 17.8613L11.0956 22.5446C10.0226 24.0597 7.64376 23.4223 7.47206 21.5736L6.94136 15.8595C6.88738 15.2783 6.58217 14.7496 6.10582 14.4123L1.42257 11.0956C-0.0925774 10.0226 0.544833 7.64371 2.3935 7.47202L8.10766 6.94131C8.68887 6.88733 9.2175 6.58213 9.55486 6.10577L12.8715 1.42252Z" fill="#FFAEA6"/>
                </svg>
                <img className={styles.shoe} src="/img/main/shoe.svg" alt=""/>
                <img  className={styles.glasses} src="/img/main/glasses.svg" alt=""/>
            </div>
        </div>

    );
};

export default Main;